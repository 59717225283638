import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// Header and Footer
import Footer from './Routings/footer';
import Header from './Routings/header';
// All sites
import App from './App';
// Art
import ArtHub from './Art/ArtHub';
import ArtDetail from './Art/ArtDetail';
// Projects
import ProjectHub from './Projects/ProjectHub';
import ProjectDetail from './Projects/ProjectDetail';
// Charecters
import CharectersHub from './Charecters/CharectersHub';
import CharectersDetail from './Charecters/CharectersDetail';
// Story
import StoryHub from './Story/StoryHub';
import StoryDetail from './Story/StoryDetail';

import ReDirect from './ReDirect';
//css
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<ReDirect />} />
      <Route path="/Home" element={<App />} />
      <Route path="/Art/ArtHub" element={<ArtHub />} />
      <Route path="/Art/ArtHub/:id" element={<ArtDetail />} />
      <Route path="/Projects/ProjectHub" element={<ProjectHub />} />
      <Route path="/Projects/ProjectHub/:id" element={<ProjectDetail />} />
      <Route path="/Charecters/CharectersHub" element={<CharectersHub />} />
      <Route path="/Charecters/CharectersHub/:id" element={<CharectersDetail />} />
      <Route path="/Story/StoryHub" element={<StoryHub />} />
      <Route path="/Story/StoryHub/:id" element={<StoryDetail />} />
      <Route path="/routings/footer" element={<Footer />} />
      <Route path="/routings/header" element={<Header />} />
    </Routes>
  </BrowserRouter>
);
reportWebVitals();