import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../Charecters/CharectersHub.css';
import Footer from '../Routings/footer';
import Header from '../Routings/header';

function CharectersDetail() {
  const { id } = useParams();
  const [Charecters, setCharecters] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace this with your GitHub raw JSON URL
    const githubJsonUrl = "https://raw.githubusercontent.com/Arachneia-Studios/Data-Arachneia.com/refs/heads/main/Charecters/Data/CharectersData.json";

    fetch(githubJsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch Charecters data");
        }
        return response.json();
      })
      .then((data) => {
        const CharectersData = data.find((item) => item.id === id);
        setCharecters(CharectersData);
      })
      .catch((err) => {
        console.error("Error fetching Charecters data:", err);
        setError("Failed to load Charecters details. Please try again later.");
      });
  }, [id]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!Charecters) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <>
        <Header />
        <div className="Charecters-detail">
            <h1>{Charecters.title}</h1>
            <img src={Charecters.largeImage} alt={Charecters.title} className="large-image" />
            <p>{Charecters.description}</p>
            <p>
                <strong>Created:</strong> {Charecters.date}
            </p>
            <div className="tags">
                <strong>Tags:</strong>
                {Charecters.tags.map((tag, index) => (
                <span key={index} className="tag">
                    #{tag}
                </span>
                ))}
            </div>
        </div>
        <Footer />
    </>
  );
}

export default CharectersDetail;
