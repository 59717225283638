import '../App.css';
import Logo from '../Logos/logo.png';
import TwitterLogo from '../Logos/x.svg';
import GithubLogo from '../Logos/github.png';
import { Link } from 'react-router-dom';
export default function Footer ()  {
    return(
        <>
            <footer>
                <div className='logobox' id='footbox'>
                    <Link to='/Home'>
                        <img src= {Logo} alt='logo' id='FootDownBad'/>
                    </Link>
                </div>
                    <div className='footbox_sosals'>
                        <div className='sosals_image'>
                        <a href="https://x.com/ArachneiaStudio" target="_blank" rel="noopener noreferrer"><img src={TwitterLogo} alt='x' id='sosalmedia'/></a>
                        </div>
                        <div className='sosals_image'>
                        <a href="https://github.com/Arachneia-Studios" target="_blank" rel="noopener noreferrer"><img src={GithubLogo} alt='github' id='sosalmedia'/></a>
                        </div>
                    </div>
                <div className='contacts' id='footbox'>
                    <h2>Contacts</h2>
                    <p>Gaia@arachneia.com</p>
                    <p>&copy; 2025 Arachneia Studios. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    )
}