import '../App.css';
import Logo from '../Logos/logo.png';
import { Link } from 'react-router-dom';
export default function Header () {
    return (
        <>
            <header>
                <div className='logobox' id='footbox'>
                    <Link to='/Home'>
                        <img src={Logo} alt='' id='Logo' />
                    </Link>
                </div>
            </header>
        </>
    );
}
