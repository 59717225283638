import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Routings/footer';
import Header from '../Routings/header';
import '../Story/StoryHub.css';

function StoryHub() {
  const [Story, setStory] = useState([]);

  useEffect(() => {
    document.title = "Story Hub";

    // Replace with your GitHub JSON URL containing Story data
    const githubJsonUrl = "https://raw.githubusercontent.com/Arachneia-Studios/Data-Arachneia.com/refs/heads/main/Story/Data/StoryData.json";

    fetch(githubJsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch Story data");
        }
        return response.json();
      })
      .then((data) => {
        setStory(data);
      })
      .catch((error) => {
        console.error("Error fetching Story data:", error);
      });
  }, []);

  return (
    <>
    <Header />
    <div className="StoryHub-container">
      <div className="StoryHub-grid">
       {Story.map((Story) => (
        <Link to={`/Story/StoryHub/${Story.id}`} key={Story.id} className="Story-grid-item">
          <div className="Story-grid-thumbnail">
            <img src={Story.largeImage} alt={Story.title} />
          </div>
          <div className="Story-grid-info">
           <h2>{Story.title}</h2>
           <p className="Story-grid-description">{Story.description}</p>
          <div className="Story-grid-tags">
           {Story.tags.map((tag, index) => (
           <span key={index} className="tag">
            #{tag}
           </span>
            ))}
          </div>
          </div>
        </Link>
            ))}
        </div>
      </div>
    <Footer />
    </>
  );
}

export default StoryHub;
