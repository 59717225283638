import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ArtHub.css';
import Footer from '../Routings/footer';
import Header from '../Routings/header';

function ArtHub() {
  const [artItems, setArtItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 15; // 5 rows with 3 items each

  useEffect(() => {
    document.title = "Arachneia - ArtHub";

    const githubJsonUrl = "https://raw.githubusercontent.com/Arachneia-Studios/Data-Arachneia.com/refs/heads/main/Art/Data/artData.json";

    fetch(githubJsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch art data");
        }
        return response.json();
      })
      .then((data) => {
        const sortedData = data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
        setArtItems(sortedData);

        const uniqueTags = [...new Set(data.flatMap((item) => item.tags))];
        setTags(uniqueTags);
      })
      .catch((error) => {
        console.error("Error fetching art data:", error);
      });
  }, []);

  const toggleTagSelection = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
    setCurrentPage(1); // Reset to first page when tags change
  };

  const clearFilters = () => {
    setSelectedTags([]);
    setCurrentPage(1);
  };

  const filteredItems = selectedTags.length
    ? artItems.filter((item) =>
        selectedTags.every((tag) => item.tags.includes(tag))
      )
    : artItems;

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const displayedItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const changePage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Header />
      <div className="ArtHub-container">
        <div className="ArtHub-grid">
          {displayedItems.map((item) => (
            <Link to={`/Art/ArtHub/${item.id}`} key={item.id} className="art-grid-item">
              <div className="art-grid-thumbnail">
                <img src={item.thumbnail} alt={item.title} />
              </div>
              <div className="art-grid-info">
                <h2>{item.title}</h2>
                <p className="art-grid-date">Created: {item.date}</p>
                <div className="art-grid-tags">
                  {item.tags.map((tag, index) => (
                    <span key={index} className="tag">
                      #{tag}
                    </span>
                  ))}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="tag-box">
          <h3>Filter by Tags</h3>
          {tags.map((tag, index) => (
            <button
              key={index}
              className={`tag-button ${selectedTags.includes(tag) ? 'active' : ''}`}
              onClick={() => toggleTagSelection(tag)}
            >
              {tag}
            </button>
          ))}
          <button className="tag-button clear" onClick={clearFilters}>
            Clear Filters
          </button>
        </div>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => changePage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`pagination-button ${
              currentPage === index + 1 ? 'active' : ''
            }`}
            onClick={() => changePage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="pagination-button"
          onClick={() => changePage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <Footer />
    </>
  );
}

export default ArtHub;
