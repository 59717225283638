import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Routings/footer';
import Header from '../Routings/header';
import '../Projects/ProjectHub.css';

function ProjectHub() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    document.title = "Project Hub";

    // Replace with your GitHub JSON URL containing project data
    const githubJsonUrl = "https://raw.githubusercontent.com/Arachneia-Studios/Data-Arachneia.com/refs/heads/main/Projects/Data/ProjectsData.json";

    fetch(githubJsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch project data");
        }
        return response.json();
      })
      .then((data) => {
        setProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, []);

  return (
    <>
    <Header />
    <div className="ProjectHub-container">
      <div className="ProjectHub-grid">
       {projects.map((project) => (
        <Link to={`/Projects/ProjectHub/${project.id}`} key={project.id} className="Project-grid-item">
          <div className="Project-grid-thumbnail">
            <img src={project.largeImage} alt={project.title} />
          </div>
          <div className="Project-grid-info">
           <h2>{project.title}</h2>
           <p className="Project-grid-description">{project.description}</p>
          <div className="Project-grid-tags">
           {project.tags.map((tag, index) => (
           <span key={index} className="tag">
            #{tag}
           </span>
            ))}
          </div>
          </div>
        </Link>
            ))}
        </div>
      </div>
    <Footer />
    </>
  );
}

export default ProjectHub;
