import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Routings/footer';
import Header from '../Routings/header';
import '../Charecters/CharectersHub.css';

function CharectersHub() {
  const [Charecters, setCharecters] = useState([]);

  useEffect(() => {
    document.title = "Charecters hub"

    // Replace with your GitHub JSON URL containing Charecters data
    const githubJsonUrl = "https://raw.githubusercontent.com/Arachneia-Studios/Data-Arachneia.com/refs/heads/main/Charecters/Data/CharectersData.json";

    fetch(githubJsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch Charecters data");
        }
        return response.json();
      })
      .then((data) => {
        setCharecters(data);
      })
      .catch((error) => {
        console.error("Error fetching Charecters data:", error);
      });
  }, []);

  return (
    <>
    <Header />
    <div className="CharectersHub-container">
      <div className="CharectersHub-grid">
       {Charecters.map((Charecters) => (
        <Link to={`/Charecters/CharectersHub/${Charecters.id}`} key={Charecters.id} className="Charecters-grid-item">
          <div className="Charecters-grid-thumbnail">
            <img src={Charecters.largeImage} alt={Charecters.title} />
          </div>
          <div className="Charecters-grid-info">
           <h2>{Charecters.title}</h2>
           <p className="Charecters-grid-description">{Charecters.description}</p>
            <div className="Charecters-grid-tags">
            {Charecters.tags.map((tag, index) => (
            <span key={index} className="tag">
              #{tag}
            </span>
              ))}
            </div>
          </div>
        </Link>
            ))}
        </div>
      </div>
    <Footer />
    </>
  );
}

export default CharectersHub;
