import { Link } from 'react-router-dom';
import './App.css';
import Footer from './Routings/footer';
import Header from './Routings/header';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = "Arachneia - Home";
  }, []);
  return (
    <>
      <Header />
        <div>
          <div className='content-box'>
            <img src='https://github.com/GaiaAlfine/Images/blob/main/Void-fix.png?raw=true' alt='Placeholder' id='bigimage'/>
          </div>
          <div className='content-box-bellow'>
            <h2>About me</h2>
            <p>This website is a personal project where I will be publishing my personal projects, games, art, and stories.</p>
            <p>In my free time I like to make games, write stories and draw. I am also making some programs for fun.</p>
            <p>I primarily code in python but I have been thinking about other coding languages.</p>
            
          </div>
          <div className='content-box'>
            <Link to="/Art/ArtHub" id='type-button'>
              <div id='button-image-div'>
                <img src='https://github.com/GaiaAlfine/Images/blob/main/Temp/Oi-Painting-Black-Hole-AI-Art-Temp.png?raw=true' alt='Placeholder' id='buttonimage'/>
              </div>
              <div id='button-text-div'>
                <h2>Art</h2>
              </div> 
            </Link>
            <Link to="/Projects/ProjectHub" id='type-button'>
              <div id='button-image-div'>
                <img src='https://github.com/GaiaAlfine/Images/blob/main/Temp/TempGameAndProjects.png?raw=true' alt='Placeholder' id='buttonimage'/>
              </div>
              <div id='button-text-div'>
                <h2>Games / Projects</h2>
              </div> 
            </Link>
            <Link to="/Charecters/CharectersHub" id='type-button'>
              <div id='button-image-div'>
                <img src='https://github.com/GaiaAlfine/Images/blob/main/Temp/CharTemp.png?raw=true' alt='Placeholder' id='buttonimage'/>
              </div>
              <div id='button-text-div'>
                <h2>Charecters</h2>
              </div> 
            </Link>
            <Link to="/Story/StoryHub" id='type-button'>
              <div id='button-image-div'>
                <img src='https://github.com/GaiaAlfine/Images/blob/main/Temp/WritingTemp.png?raw=true' alt='Placeholder' id='buttonimage'/>
              </div>
              <div id='button-text-div'>
                <h2>Storys / Lore</h2>
              </div> 
            </Link>
          </div>
        </div>
      <Footer />
    </>
  );
}
export default App;
