import React, { useEffect, useState } from 'react';
import Footer from '../Routings/footer';
import Header from '../Routings/header';
import '../App.css';
import { useParams } from 'react-router-dom';

function ArtDetail() {
  const { id } = useParams();
  const [artItem, setArtItem] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace this with your GitHub raw JSON URL
    const githubJsonUrl = "https://raw.githubusercontent.com/Arachneia-Studios/Data-Arachneia.com/refs/heads/main/Art/Data/artData.json";

    fetch(githubJsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch art data");
        }
        return response.json();
      })
      .then((data) => {
        const item = data.find((item) => item.id === id);
        setArtItem(item);
      })
      .catch((err) => {
        console.error("Error fetching art data:", err);
        setError("Failed to load art details. Please try again later.");
      });
  }, [id]);

  if (error) {
    return (
      <>
        <Header />
        <div>{error}</div>
        <Footer />
      </>
    );
  }

  if (!artItem) {
    return (
      <>
        <Header />
        <div>Loading...</div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="art-detail">
        <h1>{artItem.title}</h1>
        <img src={artItem.largeImage} alt={artItem.title} className="large-image" />
        <p>{artItem.description}</p>
        <p><strong>Created:</strong> {artItem.date}</p>
        <div className="tags">
          <strong>Tags:</strong>
          {artItem.tags.map((tag, index) => (
            <span key={index} className="tag">
              #{tag}
            </span>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ArtDetail;
